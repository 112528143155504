<template>
	<div class="contact">
		<h2 class="mb-1">Contact Chemformation</h2>
		<v-container class="mb-1">
			<v-row>
				<v-col class="d-flex flex-column align-center pt-0" cols="12" sm="12" style="font-size: 14px">
					<span>214-676-4885</span>
					<span><a href="mailto:info@chemformation.com">info@chemformation.com</a></span>
				</v-col>
			</v-row>
		</v-container>
		<v-form v-if="!isSuccess" ref="form" class="form">
			<div class="form-group-hp">
				<label>To</label>
				<v-text-field v-model="contactForm.to" :disabled="true" single-line outlined dense></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Name*</label>
				<v-text-field
					v-model="contactForm.name"
					:rules="[(v) => !!v || 'Required.']"
					required
					:disabled="contactForm.name !== null && isAuthenticated === true"
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Reply Email*</label>
				<v-text-field
					v-model="contactForm.replyEmail"
					:rules="[(v) => !!v || 'Required.']"
					required
					:disabled="contactForm.replyEmail !== null && isAuthenticated === true"
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Company Name*</label>
				<v-text-field
					v-model="contactForm.companyName"
					:rules="[(v) => !!v || 'Required.']"
					required
					:disabled="contactForm.companyName !== null && isAuthenticated === true"
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Phone Number*</label>
				<v-text-field
					v-model="contactForm.phoneNumber"
					:rules="[(v) => !!v || 'Required.']"
					required
					:disabled="contactForm.phoneNumber !== null && isAuthenticated === true"
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Subject*</label>
				<v-text-field
					v-model="contactForm.subject"
					:rules="[(v) => !!v || 'Required.']"
					required
					single-line
					outlined
					dense
					:placeholder="$route.query.subject || ''"
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label style="align-self: flex-start">Message*</label>
				<v-textarea
					v-model="contactForm.message"
					:rules="[(v) => !!v || 'Required.']"
					required
					outlined
					dense
				></v-textarea>
			</div>
			<p style="font-size: 13px; float: left; clear: left; margin-top: 1rem">
				* Indicates that the field is required.
			</p>
			<v-btn
				color="#ba0c2f"
				style="float: right; margin-top: 1rem; font-size: 1.25rem; color: white"
				@click="submit()"
			>
				Send
			</v-btn>
		</v-form>
		<template v-else>
			<span style="font-size: 16px; line-height: 25px">
				We have received your contact request. The Chemformation team will reach out to you as soon as
				possible.
			</span>
			<p v-if="redirectCountdown > 0 && isLoggedIn" style="font-size: 16px; line-height: 25px">
				Redirecting to products search in {{ redirectCountdown }}...
			</p>
		</template>

		<v-dialog v-model="showResponseModal" width="500">
			<v-card>
				<v-card-title>Response</v-card-title>
				<v-card-text style="font-size: 16px; line-height: 25px">
					<ul>
						<li v-for="(message, i) in responseMessages" :key="i">{{ message }}</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="showResponseModal = false">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "ContactUs",
	data() {
		return {
			contactForm: {
				to: "Chemformation",
				name: "",
				replyEmail: "",
				companyName: "",
				phoneNumber: "",
				subject: "",
				message: "",
			},
			isAuthenticated: false,
			errors: [],
			formError: false,
			showResponseModal: false,
			responseMessages: [],
			isSuccess: false,
			redirectCountdown: 0,
		};
	},
	methods: {
		async submit() {
			let self = this;
			this.responseMessages = [];

			//validate form
			this.validateForm();

			try {
				if (!this.formError) {
					//send api post request
					var response = await this.$http.post(`/api/account/contact`, this.contactForm);

					if (response.data.response) {
						this.isSuccess = true;

						if (this.isLoggedIn) {
							this.redirectCountdown = 3;
							let countdown = setInterval(() => {
								this.redirectCountdown--;
								if (this.redirectCountdown === 0) {
									clearInterval(countdown);
									this.$router.push("/product-search");
								}
							}, 1000);
						}
					}
				}
			} catch (error) {
				if (error.response.data) {
					error.response.data.forEach(function (item) {
						self.responseMessages.push(item);
					});
					this.showResponseModal = true;
				}
			}
		},
		validateForm() {
			this.$refs.form.validate();
			this.errors = [];

			if (!this.contactForm.name) {
				this.errors.push("Name is mandatory");
			}

			if (!this.contactForm.replyEmail) {
				this.errors.push("Reply email is mandatory");
			}

			if (!this.contactForm.companyName) {
				this.errors.push("Company name is mandatory");
			}

			if (!this.contactForm.phoneNumber) {
				this.errors.push("Phone number is mandatory");
			}

			if (!this.contactForm.subject) {
				this.errors.push("Subject is mandatory");
			}

			if (!this.contactForm.message) {
				this.errors.push("Message is mandatory");
			}

			if (this.errors.length > 0) {
				this.formError = true;
			}
		},
	},
	computed: {
		isLoggedIn() {
			return this.$store.getters.isLoggedIn;
		},
	},
	watch: {
		"$route.query.subject": {
			immediate: true,
			handler(newSubject) {
				if (newSubject) {
					this.contactForm.subject = newSubject;
				}
			},
		},
	},
	mounted() {
		let user = this.$store.state.authentication.user;

		if (user) {
			this.isAuthenticated = true;

			if (user.firstName || user.lastName) {
				this.contactForm.name = `${user.firstName} ${user.lastName}`;
			}

			if (user.email) {
				this.contactForm.replyEmail = user.email;
			}

			if (user.company) {
				this.contactForm.companyName = user.company;
			}

			if (user.primaryPhone) {
				this.contactForm.phoneNumber = user.primaryPhone;
			} else if (user.secondaryPhone) {
				this.contactForm.phoneNumber = user.secondaryPhone;
			}
		}
	},
};
</script>

<style lang="scss" scoped>
.contact {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 25px;

	h2 {
		margin: 1.5rem 0;
	}
}
</style>
